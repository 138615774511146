<style lang="less" scoped>
@import './index.less';
</style>

<template>
  <suy-table :reload="loadData" v-model:columns="columns" >
    <template #search>
      <a-form ref="formRef" :model="formState"  layout="inline" >
        <a-row :gutter="[24,12]" justify="end">
          <a-col :span="6">
            <a-form-item label="" :wrapperCol="{flex:'auto'}" name="driver">
              <a-select v-model:value="formState.truckStatus.value" placeholder="运输类型">
                <a-select-option v-for="city  in dataset" :key="city[1]"> {{ city[0] }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="" :wrapperCol="{flex:'auto'}">
              <a-input v-model:value="formState.carNo" placeholder="大车车牌" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="" :wrapperCol="{flex:'auto'}">
              <a-input v-model:value="formState.vehicleNo" placeholder="小车车牌" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="">
              <a-input v-model:value="formState.driverName" placeholder="大车司机" />
            </a-form-item>
          </a-col>
<!--          <template v-if="open">-->
            <a-col :span="6">
              <a-form-item label="" name="driver">
                <a-cascader :options="$store.state.app.cityDict" :show-search="{ filter }" :changeOnSelect="true" @change="setStartAddress" placeholder="起始地点" />
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="">
                <a-cascader :options="$store.state.app.cityDict" :show-search="{ filter }" :changeOnSelect="true" @change="setEndAddress" style="height: 38px;" placeholder="目的地点" />
              </a-form-item>
            </a-col>

            <a-col :span="6">
              <a-form-item label="">
                <a-input v-model:value="formState.truckId" placeholder="运单号" />
              </a-form-item>
            </a-col>

            <a-col :span="6">
              <a-form-item label="">
                <a-input v-model:value="formState.carrierName" placeholder="承运商名称" />
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="">
                <a-input v-model:value="formState.carrierMobile" placeholder="承运商电话" />
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="" name="dispatcher">
                <a-input v-model:value="formState.dispatcher" placeholder="派车人" />
              </a-form-item>
            </a-col>

            <a-col :span="6">
              <a-form-item label="">
                <a-date-picker v-model:value="formState.departureTime" valueFormat="YYYY-MM-DD" :format="dateFormat" placeholder="出发时间" />
              </a-form-item>
            </a-col>
<!--            <a-col :span="8">-->

<!--            </a-col>-->
<!--            <a-col :span="8">-->

<!--            </a-col>-->
<!--          </template>-->
          <a-col :span="6" style="text-align: right;">
            <a-form-item>
              <a-button type="primary" html-type="submit" @click="onClick('',1)">
                搜索
              </a-button>
              <a-button style="margin-left: 10px" @click="onClick('',2)">
                重置
              </a-button>
<!--              <a-button type="link" @click="open = !open">{{open ? '收起':'展开'}}-->
<!--                <UpOutlined v-if="open" />-->
<!--                <DownOutlined v-else />-->
<!--              </a-button>-->
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </template>
    <a-table :columns="[sortColumn,...columns]" :rowKey="(record,index)=>{return index}" :data-source="listData" :pagination="pagination" :loading="loading" bordered @change="handleTableChange" :scroll="{ x: 2000 }">
      <template #sort="{ index }" style="width:20px">
        {{index+1}}
      </template>

      <template #usedParkPlace="{ record }">
        <a @click="onClick(record,3)">{{record.usedParkPlace}}</a>
      </template>
      <template #carNo="{ record }">
        <span @click="onClick(record,7)">{{record.carNo}}</span>
      </template>
      <template #driver="{ record }">
        <span class="d89f8">{{record.driverName}}</span>
        <br />
        <span class="d89f8">{{record.driverTel}}</span>
      </template>
      <template #Status="{ record }">
        <span :class="[(record.truckStatus.value ===0) ?'f1ab18':'',(record.truckStatus.value ===1) ?'d89f8':'',(record.truckStatus.value ===2) ?'cb32f':'', (record.truckStatus.value ===3) ?'ee5c23':'',(record.truckStatus.value ===4) ?'lv':'',(record.truckStatus.value ===5) ?'e3e3e3':'' ]"> {{record.truckStatus.label}}</span>
      </template>
      <template #operation="{ record }">
        <span>
          <a @click="onClick(record,6)">查询归档</a>
        </span>
      </template>
    </a-table>
  </suy-table>

  <plan ref="plan" />
  <a-modal v-model:visible="visiblemodal" title="车辆详情" v-if="visiblemodal" @ok="onClick(positions,5)" footer={ [] } width="60%">
    <div>
      <div class="suy_table_title">
        <div>
          <a-button detailsloading="detailsloading" v-if="detailsloading" class="data_button">数据加载中</a-button>
          <div v-else>
            <div v-if="vehicleNo === null ">
              <label>暂无信息</label>
            </div>
            <a-row :gutter="[8,32]" v-else>
              <a-col :span="8">
                <label>小车车牌: </label>
                <b>{{vehicleNo}}</b>
              </a-col>
            </a-row>

          </div>
        </div>
      </div>
    </div>
  </a-modal>
  <a-drawer title="查看归档" placement="right" width="90%" v-model:visible="visible" destroyOnClose>
    <files ref="files" :transTruckId="transTruckId" />
  </a-drawer>
</template>

<script>
import { message } from 'ant-design-vue'
import { onMounted, reactive, toRefs, ref } from 'vue'
import { pageAll as bigPage } from '@/api/transport/vehicle'
import files from './file'
import transportType from '../TransportType/TransportType.json'
import plan from '../gps/plan'
import SuyTable from '@/components/SuyTable'
export default {
  data () {
    return {
      dataset: transportType.truckStatus
    }
  },
  components: { plan, SuyTable, files },

  setup (props) {
    const state = reactive({
      vehicleNo: null,
      files: null,
      open: false,
      visible: false,
      transTruckId: 0,
      baiduMapStyle: { height: '' },
      tableStyle: { width: '' },
      newList: [],
      positions: {
        nowLocation: {
          details: ''
        }
      },
      sortColumn: { title: '序号', width: 50, slots: { customRender: 'sort' } },
      visiblemodal: false,
      plan: ref(null),
      formRef: ref(),
      loading: false,
      detailsloading: false,

      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      },

      formState: {
        startAddress: {},
        start: [],
        endAddress: {},
        end: [],
        vehicleNo: '',
        driverName: '',
        carrierName: '',
        carrierMobile: '',
        departureTime: '',
        carNo: '',
        truckStatus: { label: '', value: '' }
      },
      listData: [],
      columns: [
        {
          title: '安排时间',
          width: 150,
          dataIndex: 'createTime',
          key: 'createTime'
        },
        {
          title: '发车时间',
          width: 150,
          dataIndex: 'departureTime',
          key: 'departureTime',
          ellipsis: true
        },
        {
          title: '运输状态',
          width: 100,
          dataIndex: 'truckStatus',
          key: 'truckStatus',
          slots: {
            customRender: 'Status'
          }
        },
        {
          title: '到达时间',
          width: 150,
          dataIndex: 'arriveTime',
          key: 'arriveTime',
          ellipsis: true
        },

        {
          title: '大车车牌',
          width: 110,
          dataIndex: 'carNo',
          key: 'carNo',
          slots: {
            customRender: 'carNo'
          }
        },

        {
          title: '司机信息',
          width: 130,
          dataIndex: 'driver',
          key: 'driver',
          slots: {
            customRender: 'driver'
          }
        },
        {
          title: '派车人',
          width: 100,
          dataIndex: 'dispatcher',
          key: 'dispatcher',
          ellipsis: true
        },
        {
          title: '承运商',
          width: 100,
          dataIndex: 'carrierName',
          key: 'carrierName',
          ellipsis: true
        },
        // {
        //   title: '是否到达',
        //   width: 100,
        //   dataIndex: 'isArrived.label',
        //   key: 'isArrived.label',
        //   ellipsis: true
        // },
        {
          title: '线路名称',
          dataIndex: 'lineName',
          width: 150,
          key: 'lineName'
        },
        {
          title: '超期',
          width: 300,
          dataIndex: 'overdueText',
          key: 'overdueText'
        },
        {
          title: '当前位置',
          width: 300,
          dataIndex: 'nowLocation',
          key: 'nowLocation'
        },
        {
          title: '实际剩余公里数',
          width: 100,
          dataIndex: 'remainingKm',
          key: 'remainingKm'
        },
        {
          title: '已占',
          width: 80,
          dataIndex: 'usedParkPlace',
          key: 'usedParkPlace',
          ellipsis: true,
          slots: {
            customRender: 'usedParkPlace'
          }
        },
        {
          title: '空位',
          width: 80,
          dataIndex: 'vacancy',
          key: 'vacancy',
          ellipsis: true
        },
        {
          title: '计划用时',
          width: 100,
          dataIndex: 'plannedTime',
          key: 'plannedTime'
        },
        {
          title: '实际用时',
          width: 100,
          dataIndex: 'actualTime',
          key: 'actualTime'
        },
        {
          title: '大车总运费',
          width: 150,
          dataIndex: 'freightCost',
          key: 'freightCost',
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 100,
          fixed: 'right',
          slots: {
            customRender: 'operation'
          }
        }
      ]

    })

    const loadData = async () => {
      state.loading = true
      const { data, data: { records } } = await bigPage({
        ...state.formState,
        current: state.pagination.current,
        size: state.pagination.pageSize,
        name: state.formState.carNo
      })
      state.listData = records
      state.pagination.current = data.current
      state.pagination.pageSize = data.size
      state.pagination.total = data.total
      state.loading = false
    }

    onMounted(loadData)

    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }

    const onClick = (recode, type) => {
      switch (type) {
        case 1:
          state.pagination.current = 1
          loadData()
          break
        case 2:
          state.formRef.resetFields()
          break
        case 3:
          state.plan.ontransTruckId(recode.transTruckId)
          state.plan.onSwitch(true)
          state.plan.loadData()
          break
        case 4:
          state.visiblemodal = true
          state.detailsloading = true
          state.vehicleNo = recode.vehicleNo
          setTimeout(() => {
            state.detailsloading = false
          }, 1000)
          break
        case 5:
          state.visiblemodal = false
          state.vehicleNo = null
          break
        case 6:
          state.transTruckId = recode.transTruckId
          state.visible = true
          break
        case 7:
          state.plan.ontransTruckId(recode.transTruckId)
          state.plan.onSwitch(true)
          state.plan.loadData()
          break
        default:
          message.success('出现错误' + type)
      }
    }

    const setStartAddress = (value, selectedOptions) => {
      state.formState.start = selectedOptions
      state.formState.startAddress.province = value[0]
      state.formState.startAddress.city = value[1]
      state.formState.startAddress.area = value[2]
    }

    const setEndAddress = (value, selectedOptions) => {
      state.formState.end = selectedOptions
      state.formState.endAddress.province = value[0]
      state.formState.endAddress.city = value[1]
      state.formState.endAddress.area = value[2]
    }

    return {
      onClick,
      loadData,
      handleTableChange,
      setEndAddress,
      setStartAddress,
      ...toRefs(state)
    }
  }

}
</script>
<style lang="less" scoped>
.f1ab18 {
  color: red;
}
.d89f8 {
  color: #5d89f8;
}
.cb32f {
  color: #9cb32f;
}
.lv {
  color: #34b969;
}
.ee5c23 {
  color: #ee5c23;
}
.e3e3e3 {
  color: #e3e3e3;
}
.f47920 {
  color: red;
}
.e {
  color: #40835e;
}
.cd7 {
  color: #317cd7;
}
</style>
