<style lang="less" scoped>
@import './index.less';
</style>
<template>
  <div id="printOrder">
    <div>
      <h1 class="file-h1">
        成都华月物流托运清单 运单编号：{{ form.transTruckId }}
      </h1>
    </div>
    <div class="file-body">
      <div class="file-body-head">
        <did class="filingTime">
          归档时间：{{ form.fileTime }}
        </did>
        <did class="line">
          线路：{{ form.lineName }}
        </did>
      </div>
      <div class="file-body-body">
        <table border="1" width="100%" style="text-align: center">
          <tbody>
            <tr>
              <td rowspan="2">
                序号
              </td>
              <td rowspan="2">
                合同编号
              </td>
              <td rowspan="2">
                客户
              </td>
              <td rowspan="2">
                业务员
              </td>
              <td colspan="2">
                轿车运输线路
              </td>
              <td colspan="2">
                车辆信息
              </td>
              <td rowspan="2">
                车到收
              </td>
              <td rowspan="2">
                资金
              </td>
              <td colspan="3">
                目的地收车人信息
              </td>
              <td colspan="2">
                提车
              </td>
            </tr>
            <tr>
              <td>
                起运地
              </td>
              <td>
                目的地
              </td>
              <td>
                车辆名称
              </td>
              <td>
                车牌架号
              </td>
              <td>
                收车人
              </td>

              <td>
                电话
              </td>
              <td>
                证件
              </td>
              <td>
                方式
              </td>
              <td>
                地址
              </td>
            </tr>
            <tr v-for="(content ,index) in form.details" :key="index">
              <td>
                {{ index+1 }}
              </td>
              <td>
                {{ content.id }}
              </td>
              <td>
                {{ content.customerName }}
              </td>
              <td>
                {{ content.salesman }}
              </td>
              <td>
                {{ content.startAddress }}
              </td>
              <td>
                {{ content.endAddress }}
              </td>
              <td>
                {{ content.brand }} {{ content.model }}
              </td>
              <td>
                {{ content.vinNo }}
              </td>
              <td>
                ￥ {{ content.paidAmt }}
              </td>
              <td>
                {{content.orderFund.value}}
              </td>
              <td>
                {{ content.picker }}
              </td>
              <td>
                {{ content.pickerMobile }}
              </td>
              <td>
                {{ content.pickerCertificateNumber }}
              </td>
              <td v-if="content.isTakeOut.value === 0">
                自提
              </td>
              <td v-if="content.isTakeOut.value === 1">
                送车
              </td>
              <td>
                {{ content.takeOutAddress }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="file-body-foot">
        <div class="foot">司机：{{ form.driverName }} 电话:{{ form.driverTel }}</div>
        <div class="foot">经手人：____________</div>
        <div class="foot">经手人：____________ 年 ______ 月 ______ 日</div>
        <div class="foot line">制单：{{ form.former }}</div>
        <div class="foot line">{{ form.fileDate}}</div>
      </div>
    </div>
  </div>
  <a-space>
    <a-button @click="pdfDownload">清单下载</a-button>
  </a-space>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { vehicleTruckOnFile } from '@/api/transport/vehicle'
import html2canvas from 'html2canvas'
import { jsPDF as JsPDF } from 'jspdf'
// import {message} from "ant-design-vue";

export default {
  props: {
    transTruckId: { type: String, default: '' }
  },
  setup (props) {
    const state = reactive({
      visible: false,
      form: {},
      transTruckId: 0
    })

    const loadData = async (id) => {
      state.loading = true
      const { data } = await vehicleTruckOnFile(id)
      state.form = data
      state.loading = false
    }
    loadData(props.transTruckId)

    const pdfDownload = () => {
      html2canvas(document.querySelector('#printOrder')).then(canvas => {
        const contentWidth = canvas.width
        const contentHeight = canvas.height
        // 一页pdf显示html页面生成的canvas高度;
        const pageHeight = contentWidth / 592.28 * 841.89
        // 未生成pdf的html页面高度
        let leftHeight = contentHeight
        // 页面偏移
        let position = 20
        // a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
        const imgWidth = 555.28
        const imgHeight = 552.28 / contentWidth * contentHeight
        const pageData = canvas.toDataURL('image/jpeg', 1.0)
        const pdf = new JsPDF('', 'pt', 'a4')
        // 有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
        // 当内容未超过pdf一页显示的范围，无需分页
        if (leftHeight < pageHeight) {
          pdf.addImage(pageData, 'JPEG', 20, 0, imgWidth, imgHeight)
        } else {
          while (leftHeight > 0) {
            pdf.addImage(pageData, 'JPEG', 20, position, imgWidth, imgHeight)
            leftHeight -= pageHeight
            position -= 841.89
            // 避免添加空白页
            if (leftHeight > 0) {
              pdf.addPage()
            }
          }
        }
        pdf.save(`车辆归档-${state.form.transTruckId}.pdf`)
      })
    }
    return {
      loadData,
      ...toRefs(state),
      pdfDownload
    }
  }

}
</script>

<style scoped>
</style>
